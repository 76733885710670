// @flow
import * as React from 'react'
import { Store } from 'Store'

import PromoVoucher from 'components/PromoVoucher'

import usePromoCode from 'util/hooks/usePromoCode'

import styles from './styles.module.scss'

function PromoHouse (props) {
    const promoCode = usePromoCode()
    const { dispatch } = React.useContext(Store)

    React.useEffect(() => {
        if (props.match.params.locale) {
            dispatch({
                type: 'SET_LOCALE',
                payload: new Intl.Locale(props.match.params.locale),
            })
        }
    }, [dispatch, props.match.params.locale])

    return (
        <div className={styles.container}>
            <div className={styles.voucher}>
                <PromoVoucher width="100%" promoCode={promoCode}/>
            </div>

            <div className={styles.voucher}>
                <PromoVoucher width="100%" promoCode={promoCode}/>
            </div>

            <div className={styles.voucher}>
                <PromoVoucher width="100%" promoCode={promoCode}/>
            </div>
        </div>
    )
}

export default PromoHouse
