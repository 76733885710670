// @flow
import React from 'react'
import cn from 'classnames'

import { Store } from 'Store'
import { fetchCampaign } from 'Actions'

import styles from 'components/LocaleSwitcher/styles.module.scss'

export default function LocaleSwitcher () {
    const { state, dispatch } = React.useContext(Store)

    const handleClick = React.useCallback(async e => {
        e.preventDefault()

        await fetchCampaign(dispatch, e.target.dataset['locale'])
    }, [dispatch])

    if (state.campaign.locales.supported.length < 2) {
        return null
    }

    return (
        <div className={styles.switcher}>
            <div className={cn(styles.switcherBg, {
                [styles.overflow]: state.campaign.locales.supported.length > 2
            })}>
                {state.campaign.locales.supported.map(l => (
                    <div
                        key={l}
                        className={cn(styles.button, {
                            [styles.active]: state.locale.toString() === l
                        })}
                        data-locale={l}
                        onClick={handleClick}>
                        {formatLocaleLabel(l)}
                    </div>
                ))}
            </div>
        </div>
    )
}

function formatLocaleLabel (locale) {
    return new Intl.Locale(locale).language
}
