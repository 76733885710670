// @flow
import React from 'react'

const SlideHandleLeft = ({ arrowColor, bgColor }) => {
    return (
        <svg width="18px" viewBox="0 0 18 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <g>
                <path d="M0,32 C10.1156196,31.7645391 18,24.836556 18,16 C18,7.163444 9.9411255,0 0,0 C0,0 0,7.163444 0,16 C0,24.836556 0,32 0,32 Z" id="Oval-Copy" fill={bgColor || '#099BE4'} transform="translate(9.000000, 16.000000) scale(-1, 1) translate(-9.000000, -16.000000) "></path>
                <polygon fill={arrowColor || '#FFFFFF'} transform="translate(10.000000, 15.500000) rotate(-90.000000) translate(-10.000000, -15.500000) " points="10 12 16 19 4 19"></polygon>
            </g>
        </svg>
    )
}

export default SlideHandleLeft
