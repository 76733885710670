// @flow

export function makeCampaign (style: string) {
    return {
        locales: {
            default: 'en-CA',
            supported: ['fr-CA'] // will be appended to the locales.supported from the mock
        },
        // incentives: {
        //     voucher: {
        //         type: 'fixed',
        //         value: 0
        //     }
        // },
        videos: {
            sidebar: `https://s3.amazonaws.com/com.shift-marketing.videos.demo/upgrade-sale-2019-1/${style}.mp4`
        }
    }
}
