import * as React from 'react'
import withTheme from 'hoc/withTheme'
import { Store } from 'Store.js'
import { Currency } from 'i18n/NumberFormatter'
import transDomain from './translations/index.translations'

import supportedThemes from './themes/__supportedThemes.js'
import { withTranslation, Trans } from 'react-i18next'

import COSTCO from './images/costco.png'

import styles from './styles.module.scss'

const CostcoVoucher = ({ t }) => {
    const { state } = React.useContext(Store)
    const currencyFormatter = Currency(state.currency, state.locale)
    const value = currencyFormatter.format(1000)
    return (
        <div className={styles.costco}>
            <h3 className={styles.costcoInfo}>
                <Trans t={t} i18nKey={'voucher_title'}>
                    Costco Members receive an additional {{ value }} toward their finance or lease
                </Trans>
            </h3>
            <div
                className={styles.img}
                style={{ backgroundImage: `url(${COSTCO})` }}
            />
        </div>
    )
}

export default withTranslation(transDomain)(withTheme(supportedThemes)(CostcoVoucher))
