
        import i18n from 'i18n';
        import flattenObject from 'util/flattenObject';
    
            import ns223abed8fa71029438c698e4733261e8en from './en.yaml'
            i18n.addResourceBundle('en', '223abed8fa71029438c698e4733261e8', flattenObject(ns223abed8fa71029438c698e4733261e8en), true, true);
        
            import ns223abed8fa71029438c698e4733261e8fr from './fr.yaml'
            i18n.addResourceBundle('fr', '223abed8fa71029438c698e4733261e8', flattenObject(ns223abed8fa71029438c698e4733261e8fr), true, true);
        
        export default '223abed8fa71029438c698e4733261e8';
    