
        import i18n from 'i18n';
        import flattenObject from 'util/flattenObject';
    
            import ns6da38c7874819911ddfedab1de7be520en from './en.yaml'
            i18n.addResourceBundle('en', '6da38c7874819911ddfedab1de7be520', flattenObject(ns6da38c7874819911ddfedab1de7be520en), true, true);
        
            import ns6da38c7874819911ddfedab1de7be520fr from './fr.yaml'
            i18n.addResourceBundle('fr', '6da38c7874819911ddfedab1de7be520', flattenObject(ns6da38c7874819911ddfedab1de7be520fr), true, true);
        
        export default '6da38c7874819911ddfedab1de7be520';
    