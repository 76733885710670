
        import i18n from 'i18n';
        import flattenObject from 'util/flattenObject';
    
            import ns8b583baa448eff04e3ee1dc9ec7149bben from './en.yaml'
            i18n.addResourceBundle('en', '8b583baa448eff04e3ee1dc9ec7149bb', flattenObject(ns8b583baa448eff04e3ee1dc9ec7149bben), true, true);
        
            import ns8b583baa448eff04e3ee1dc9ec7149bbfr from './fr.yaml'
            i18n.addResourceBundle('fr', '8b583baa448eff04e3ee1dc9ec7149bb', flattenObject(ns8b583baa448eff04e3ee1dc9ec7149bbfr), true, true);
        
        export default '8b583baa448eff04e3ee1dc9ec7149bb';
    