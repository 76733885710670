
        import i18n from 'i18n';
        import flattenObject from 'util/flattenObject';
    
            import ns0a837832ed8e80d67b9fa2c5d17e3aaaen from './en.yaml'
            i18n.addResourceBundle('en', '0a837832ed8e80d67b9fa2c5d17e3aaa', flattenObject(ns0a837832ed8e80d67b9fa2c5d17e3aaaen), true, true);
        
            import ns0a837832ed8e80d67b9fa2c5d17e3aaafr from './fr.yaml'
            i18n.addResourceBundle('fr', '0a837832ed8e80d67b9fa2c5d17e3aaa', flattenObject(ns0a837832ed8e80d67b9fa2c5d17e3aaafr), true, true);
        
        export default '0a837832ed8e80d67b9fa2c5d17e3aaa';
    