
        import i18n from 'i18n';
        import flattenObject from 'util/flattenObject';
    
            import ns21ecd69ebf127afcb0f2339e0921c9d9en from './en.yaml'
            i18n.addResourceBundle('en', '21ecd69ebf127afcb0f2339e0921c9d9', flattenObject(ns21ecd69ebf127afcb0f2339e0921c9d9en), true, true);
        
            import ns21ecd69ebf127afcb0f2339e0921c9d9fr from './fr.yaml'
            i18n.addResourceBundle('fr', '21ecd69ebf127afcb0f2339e0921c9d9', flattenObject(ns21ecd69ebf127afcb0f2339e0921c9d9fr), true, true);
        
        export default '21ecd69ebf127afcb0f2339e0921c9d9';
    