
        import i18n from 'i18n';
        import flattenObject from 'util/flattenObject';
    
            import nsdfdf1aa43d42fa971ad32d966e49fcbfen from './en.yaml'
            i18n.addResourceBundle('en', 'dfdf1aa43d42fa971ad32d966e49fcbf', flattenObject(nsdfdf1aa43d42fa971ad32d966e49fcbfen), true, true);
        
            import nsdfdf1aa43d42fa971ad32d966e49fcbffr from './fr.yaml'
            i18n.addResourceBundle('fr', 'dfdf1aa43d42fa971ad32d966e49fcbf', flattenObject(nsdfdf1aa43d42fa971ad32d966e49fcbffr), true, true);
        
        export default 'dfdf1aa43d42fa971ad32d966e49fcbf';
    