
        import i18n from 'i18n';
        import flattenObject from 'util/flattenObject';
    
            import nse0ef169c969fb18bf508206fb293e3a8en from './en.yaml'
            i18n.addResourceBundle('en', 'e0ef169c969fb18bf508206fb293e3a8', flattenObject(nse0ef169c969fb18bf508206fb293e3a8en), true, true);
        
            import nse0ef169c969fb18bf508206fb293e3a8fr from './fr.yaml'
            i18n.addResourceBundle('fr', 'e0ef169c969fb18bf508206fb293e3a8', flattenObject(nse0ef169c969fb18bf508206fb293e3a8fr), true, true);
        
        export default 'e0ef169c969fb18bf508206fb293e3a8';
    