
        import i18n from 'i18n';
        import flattenObject from 'util/flattenObject';
    
            import nsb2a7f5a0e7f72ce33918702e1efc2fd0en from './en.yaml'
            i18n.addResourceBundle('en', 'b2a7f5a0e7f72ce33918702e1efc2fd0', flattenObject(nsb2a7f5a0e7f72ce33918702e1efc2fd0en), true, true);
        
            import nsb2a7f5a0e7f72ce33918702e1efc2fd0fr from './fr.yaml'
            i18n.addResourceBundle('fr', 'b2a7f5a0e7f72ce33918702e1efc2fd0', flattenObject(nsb2a7f5a0e7f72ce33918702e1efc2fd0fr), true, true);
        
        export default 'b2a7f5a0e7f72ce33918702e1efc2fd0';
    