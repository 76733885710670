// @flow
import React from 'react'

const SlideHandleRight = ({ arrowColor, bgColor }) => {
    return (
        <svg width="18px" viewBox="0 0 18 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <g>
                <path d="M0,32 C10.1156196,31.7645391 18,24.836556 18,16 C18,7.163444 9.9411255,0 0,0 C0,0 0,7.163444 0,16 C0,24.836556 0,32 0,32 Z" id="Oval" fill={bgColor || '#FFFFFF'}></path>
                <polygon fill={arrowColor || '#099BE4'} transform="translate(8.000000, 15.500000) scale(-1, -1) rotate(-90.000000) translate(-8.000000, -15.500000) " points="8 12 14 19 2 19"></polygon>
            </g>
        </svg>
    )
}

export default SlideHandleRight
