
        import i18n from 'i18n';
        import flattenObject from 'util/flattenObject';
    
            import nsa63aa048814e7b786d84f87321d2c072en from './en.yaml'
            i18n.addResourceBundle('en', 'a63aa048814e7b786d84f87321d2c072', flattenObject(nsa63aa048814e7b786d84f87321d2c072en), true, true);
        
            import nsa63aa048814e7b786d84f87321d2c072fr from './fr.yaml'
            i18n.addResourceBundle('fr', 'a63aa048814e7b786d84f87321d2c072', flattenObject(nsa63aa048814e7b786d84f87321d2c072fr), true, true);
        
        export default 'a63aa048814e7b786d84f87321d2c072';
    