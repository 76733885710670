// @flow
import * as React from 'react'
import cn from 'classnames'

import { withTranslation } from 'react-i18next'
import withTheme from 'hoc/withTheme'

import { Store } from 'Store'

import styles from './styles.module.scss'
import supportedThemes from './themes/__supportedThemes.js'

import Modal from 'components/Modal'
import PlayButton from 'components/PlayButton'
import VideoPlayer from 'components/VideoPlayer'

import transDomain from './translations/index.translations'

function VideoThumbnail (props) {
    const { state } = React.useContext(Store)
    const { t, theme } = props
    const videoPlayer = React.useRef()
    const [ isModalOpen, setIsModalOpen ] = React.useState(false)

    const playVideo = React.useCallback(() => {
        videoPlayer.current.play()
    }, [ videoPlayer ])

    const handleOpenModal = React.useCallback(() => {
        if (!state.campaign.videos.sidebar) return alert('Video not available')
        setIsModalOpen(true)
    }, [ state.campaign.videos.sidebar ])

    const handleCloseModal = React.useCallback(() => {
        setIsModalOpen(false)
    }, [])

    return (
        <>
            <div
                className={cn(styles.thumbnail, (props.className || null))}
                style={{ backgroundImage: `url(${theme.background[props.background || 0]})`, ...(props.style || {}) }}
            >
                {(state.campaign.videos.sidebar && !theme.withNoVideo) && (
                    <div className={styles.playButton}>
                        <PlayButton onClick={handleOpenModal} fill={theme.playButtonBackground} />
                    </div>
                )}
            </div>

            <div className={styles.title} style={{ color: theme.color }}>{t('title')}</div>

            {(state.campaign.videos.sidebar && !theme.withNoVideo) && (
                <Modal afterOpen={playVideo} isOpen={isModalOpen} onCancel={handleCloseModal}>
                    <VideoPlayer src={state.campaign.videos.sidebar} ref={videoPlayer}/>
                </Modal>
            )}
        </>
    )
}

export default withTranslation(transDomain)(withTheme(supportedThemes)(VideoThumbnail))
