
        import i18n from 'i18n';
        import flattenObject from 'util/flattenObject';
    
            import ns85a6b270af994e5853366ead9619bb8aen from './en.yaml'
            i18n.addResourceBundle('en', '85a6b270af994e5853366ead9619bb8a', flattenObject(ns85a6b270af994e5853366ead9619bb8aen), true, true);
        
            import ns85a6b270af994e5853366ead9619bb8afr from './fr.yaml'
            i18n.addResourceBundle('fr', '85a6b270af994e5853366ead9619bb8a', flattenObject(ns85a6b270af994e5853366ead9619bb8afr), true, true);
        
        export default '85a6b270af994e5853366ead9619bb8a';
    