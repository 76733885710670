
        import i18n from 'i18n';
        import flattenObject from 'util/flattenObject';
    
            import ns3fbc8e67dab1a314fe733fcdeab318c7en from './en.yaml'
            i18n.addResourceBundle('en', '3fbc8e67dab1a314fe733fcdeab318c7', flattenObject(ns3fbc8e67dab1a314fe733fcdeab318c7en), true, true);
        
            import ns3fbc8e67dab1a314fe733fcdeab318c7fr from './fr.yaml'
            i18n.addResourceBundle('fr', '3fbc8e67dab1a314fe733fcdeab318c7', flattenObject(ns3fbc8e67dab1a314fe733fcdeab318c7fr), true, true);
        
        export default '3fbc8e67dab1a314fe733fcdeab318c7';
    