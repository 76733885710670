
        import i18n from 'i18n';
        import flattenObject from 'util/flattenObject';
    
            import ns2688b19fd184f8690d595beef8d2d0e4en from './en.yaml'
            i18n.addResourceBundle('en', '2688b19fd184f8690d595beef8d2d0e4', flattenObject(ns2688b19fd184f8690d595beef8d2d0e4en), true, true);
        
            import ns2688b19fd184f8690d595beef8d2d0e4fr from './fr.yaml'
            i18n.addResourceBundle('fr', '2688b19fd184f8690d595beef8d2d0e4', flattenObject(ns2688b19fd184f8690d595beef8d2d0e4fr), true, true);
        
        export default '2688b19fd184f8690d595beef8d2d0e4';
    