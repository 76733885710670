
        import i18n from 'i18n';
        import flattenObject from 'util/flattenObject';
    
            import ns502728e4879ad85fb3278ff3d0344338en from './en.yaml'
            i18n.addResourceBundle('en', '502728e4879ad85fb3278ff3d0344338', flattenObject(ns502728e4879ad85fb3278ff3d0344338en), true, true);
        
            import ns502728e4879ad85fb3278ff3d0344338fr from './fr.yaml'
            i18n.addResourceBundle('fr', '502728e4879ad85fb3278ff3d0344338', flattenObject(ns502728e4879ad85fb3278ff3d0344338fr), true, true);
        
        export default '502728e4879ad85fb3278ff3d0344338';
    