// @flow
import React from 'react'
import { makeDateFormatters, FormatType } from 'i18n/DateFormat'
import { Store } from 'Store'

export default function AbsoluteTime (props: {
    style?: Object,
    className?: string,
    date: Date,
    dateFormat?: FormatType,
    timeFormat?: FormatType,
    noContainer?: boolean,
}) {
    const { state } = React.useContext(Store)
    const dateFormatter = React.useMemo(() => {
        return makeDateFormatters(state.locale, props.dateFormat, props.timeFormat)
    }, [props.dateFormat, props.timeFormat, state.locale])

    try {
        const date = dateFormatter.format(props.date)

        return (
            props.noContainer ? <>{date}</> : <span className={props.className} style={props.style}>{date}</span>
        )
    } catch (err) {
        console.error(err)
        return (<span>[[ ERROR ]]</span>)
    }
}
