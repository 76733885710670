
        import i18n from 'i18n';
        import flattenObject from 'util/flattenObject';
    
            import ns89645a56455aeb41495a8bf89b7151fben from './en.yaml'
            i18n.addResourceBundle('en', '89645a56455aeb41495a8bf89b7151fb', flattenObject(ns89645a56455aeb41495a8bf89b7151fben), true, true);
        
            import ns89645a56455aeb41495a8bf89b7151fbfr from './fr.yaml'
            i18n.addResourceBundle('fr', '89645a56455aeb41495a8bf89b7151fb', flattenObject(ns89645a56455aeb41495a8bf89b7151fbfr), true, true);
        
        export default '89645a56455aeb41495a8bf89b7151fb';
    