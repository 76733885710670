// @flow
import * as React from 'react'

import { withTranslation } from 'react-i18next'
import withTheme from 'hoc/withTheme'
import cn from 'classnames'

import RegisterVoucherValue from 'components/RegisterVoucherValue'
import CashbackAndRates from 'components/CashbackAndRates'
import CostcoVoucher from 'components/CostcoVoucher'

import transDomain from './translations/index.translations'

import supportedThemes from './themes/__supportedThemes.js'

import styles from './styles.module.scss'

function RegisterIncentives (props) {
    return (
        <div className={styles.inner}>
            <div className={cn(styles.incentivesWrapper, {
                [styles.withCostcoVoucher]: props.theme.withCostcoVoucher
            })}>
                <div className={styles.registerVouchersWrapper}>
                    <RegisterVoucherValue/>
                    {props.theme.withCostcoVoucher && <CostcoVoucher/>}
                </div>
                <CashbackAndRates/>
            </div>
        </div>
    )
}

export default withTranslation(transDomain)(withTheme(supportedThemes)(RegisterIncentives))
