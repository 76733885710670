// @flow
import React from 'react'

export const Store = React.createContext()

const initialState = {
    locale: null,
    currency: null,
    campaign: null,
    code: null,
    lead: null,
    errors: {},
    autoresponderShouldBeSent: false
}

function reducer (state, action) {
    switch (action.type) {
        case 'SET_LOCALE':
            const locale = action.payload
            const currency = locale.region === 'US' ? 'USD' : 'CAD'
            return { ...state, locale, currency }
        case 'SET_CAMPAIGN':
            return { ...state, campaign: action.payload }
        case 'SET_LEAD':
            return { ...state, lead: action.payload }
        case 'SET_CODE':
            return { ...state, code: action.payload }
        case 'CAMPAIGN_NOT_FOUND':
            return {
                ...state,
                errors: {
                    ...(state.errors || {}),
                    campaignNotFound: true
                }
            }
        case 'MARK_AUTORESPONDER_AS_PENDING':
            return {
                ...state,
                autoresponderShouldBeSent: state.campaign.hasOwnProperty('autoresponder')
            }
        case 'MARK_AUTORESPONDER_AS_SENT':
            return { ...state, autoresponderShouldBeSent: false }
        default:
            throw new Error(`Action of type "${action.type}" is not defined`)
    }
}

export function StoreProvider (props) {
    const [state, dispatch] = React.useReducer(reducer, initialState)
    const value = { state, dispatch }

    return <Store.Provider value={value}>{props.children}</Store.Provider>
}
