// @flow
import * as React from 'react'
import { Redirect } from 'react-router-dom'

import { Currency } from 'i18n/NumberFormatter'
import { Store } from 'Store'
import { deleteLead } from 'Actions'

import { useTranslation } from 'react-i18next'
import transDomain from './translations/index.translations'

import withTheme from 'hoc/withTheme'
import supportedThemes from './themes/__supportedThemes'

import Button from 'components/Button'
import HeaderWithVoucher from 'components/HeaderWithVoucher'

import styles from './styles.module.scss'
import UpdateEmailForm from 'components/UpdateEmailForm'

function SuccessInfo (props) {
    const { theme } = props
    const { state, dispatch } = React.useContext(Store)
    const [isDone, setIsDone] = React.useState(false)
    const { t } = useTranslation(transDomain)
    const currencyFormatter = Currency(state.currency, state.locale)

    if (isDone) { // Redirect back to registration page
        return <Redirect to={`${window.location.pathname.replace('/success', '')}${window.location.search}`} push/>
    }

    return (
        <div className={styles.container}>
            <HeaderWithVoucher />

            <div className={styles.inner}>
                <div className={styles.congratsContainer}>
                    <p className={styles.congratsTitle} style={{ color: theme.congratsColor }}>
                        {t('congrats', {
                            firstName: state.lead.fields.firstName,
                            voucherValue: currencyFormatter.format(state.lead.incentives.voucher.value),
                            voucherName: state.lead.incentives.voucher.name,
                            make: state.lead.fields.newVehicleMake,
                            model: state.lead.fields.newVehicleModel,
                            year: state.lead.fields.newVehicleModelYear,
                        })}
                    </p>

                    <p className={styles.congratsSubtitle}>
                        {t('final_details', {
                            year: state.lead.fields.newVehicleModelYear,
                            make: state.lead.fields.newVehicleMake,
                            model: state.lead.fields.newVehicleModel,
                        })}
                    </p>
                </div>

                <div className={styles.finalDetails}>
                    <UpdateEmailForm/>

                    <Button
                        onClick={() => {
                            deleteLead(dispatch)
                            setIsDone(true)
                        }}
                        style={{ color: '#fff', backgroundColor: theme.primary }}
                    >{t('button')}</Button>
                </div>
            </div>
        </div>
    )
}

export default withTheme(supportedThemes)(SuccessInfo)
