
        import i18n from 'i18n';
        import flattenObject from 'util/flattenObject';
    
            import ns0bab9f3b8920f908a8a09c64cec8fc2den from './en.yaml'
            i18n.addResourceBundle('en', '0bab9f3b8920f908a8a09c64cec8fc2d', flattenObject(ns0bab9f3b8920f908a8a09c64cec8fc2den), true, true);
        
            import ns0bab9f3b8920f908a8a09c64cec8fc2dfr from './fr.yaml'
            i18n.addResourceBundle('fr', '0bab9f3b8920f908a8a09c64cec8fc2d', flattenObject(ns0bab9f3b8920f908a8a09c64cec8fc2dfr), true, true);
        
        export default '0bab9f3b8920f908a8a09c64cec8fc2d';
    