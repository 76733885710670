
        import i18n from 'i18n';
        import flattenObject from 'util/flattenObject';
    
            import nsf2d3012e2da8b014d5f85abb42558240en from './en.yaml'
            i18n.addResourceBundle('en', 'f2d3012e2da8b014d5f85abb42558240', flattenObject(nsf2d3012e2da8b014d5f85abb42558240en), true, true);
        
            import nsf2d3012e2da8b014d5f85abb42558240fr from './fr.yaml'
            i18n.addResourceBundle('fr', 'f2d3012e2da8b014d5f85abb42558240', flattenObject(nsf2d3012e2da8b014d5f85abb42558240fr), true, true);
        
        export default 'f2d3012e2da8b014d5f85abb42558240';
    