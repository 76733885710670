
        import i18n from 'i18n';
        import flattenObject from 'util/flattenObject';
    
            import ns28e16ac94de32bf96dbb7f30c19f7929en from './en.yaml'
            i18n.addResourceBundle('en', '28e16ac94de32bf96dbb7f30c19f7929', flattenObject(ns28e16ac94de32bf96dbb7f30c19f7929en), true, true);
        
            import ns28e16ac94de32bf96dbb7f30c19f7929fr from './fr.yaml'
            i18n.addResourceBundle('fr', '28e16ac94de32bf96dbb7f30c19f7929', flattenObject(ns28e16ac94de32bf96dbb7f30c19f7929fr), true, true);
        
        export default '28e16ac94de32bf96dbb7f30c19f7929';
    