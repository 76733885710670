
        import i18n from 'i18n';
        import flattenObject from 'util/flattenObject';
    
            import ns9c65167d759169af821ccdfde40e1a04en from './en.yaml'
            i18n.addResourceBundle('en', '9c65167d759169af821ccdfde40e1a04', flattenObject(ns9c65167d759169af821ccdfde40e1a04en), true, true);
        
            import ns9c65167d759169af821ccdfde40e1a04fr from './fr.yaml'
            i18n.addResourceBundle('fr', '9c65167d759169af821ccdfde40e1a04', flattenObject(ns9c65167d759169af821ccdfde40e1a04fr), true, true);
        
        export default '9c65167d759169af821ccdfde40e1a04';
    